/* 5 - About Us Section
---------------------------------------------------------------------- */
.team-container .col-sm-4{
	padding:60px 15px 0 15px;
}
.team-container .team-member{
	max-width:245px;
	margin:auto;
	position:relative;
	overflow:hidden;
	margin-top: 15px;
	margin-bottom: 15px;
}


.team-container figcaption{
	text-align:center;
	text-transform:uppercase;
	width:100%;
	height:80px;
	border:1px solid #fff;
	border-top-color:transparent;
	margin:0;
}
.team-container figcaption h4{
	font-size:22px;
	font-weight:700;
	padding-top:20px;
}
.team-container .member-name {
	/*font-size: 1.5em;*/
	font-weight: 700;
	margin: 0;
	padding: 26px 10px 5px 10px;
}
.team-container figcaption p{
	font-size: 18px;
}
.team-container .social-buttons{
	display:inline-block;
	margin:auto;
	text-align:center;
	width:100%;
}
.team-member {
	border-color: rgba(0,0,0,0.8);
	border-style: none;
}

.team-member figcaption{
	background-color: rgba(0,0,0,0.8);
	color: white;
	border: 0px;
}

.team-member .social-btn-container,
.team-member .social-btn-container .social-btn-box{
	-webkit-transition:all .25s;
	   -moz-transition:all .25s;
	    -ms-transition:all .25s;
	     -o-transition:all .25s;
	        transition:all .25s;
}

.team-member .social-btn-container {
	position: absolute;
	top: 0px;
	margin: 0 auto;
	left: 0;
}
.team-member:hover .social-btn-container{
	background: rgba(0,0,0,.5);
}
.team-member .social-btn-container .team-socail-btn{
	overflow: hidden;
	position: relative;
	width:245px;
	height: 230px;
}
.team-member .social-btn-container .social-btn-box{
	position: absolute;

}
.team-member .social-btn-container .facebook-btn-container{
	bottom: 220px;
	right: 230px;
}
.team-member .social-btn-container .twitter-btn-container{
	bottom: 220px;
	left: 230px;
}
.team-member .social-btn-container .linkedin-btn-container{
	top: 220px;
	right: 230px;
}
.team-member .social-btn-container .github-btn-container{
	top: 220px;
	left: 230px;
}

.team-member:hover .social-btn-container .facebook-btn-container{
	bottom: 115px;
	right: 122px;
}
.team-member:hover .social-btn-container .twitter-btn-container{
	bottom: 115px;
	left: 122px;
}
.team-member:hover .social-btn-container .linkedin-btn-container{
	top: 115px;
	right: 122px;
}
.team-member:hover .social-btn-container .github-btn-container{
	top: 115px;
	left: 122px;
}
.team-container .social-buttons a{
	color:#fff;
	width:24px;
	height:24px;
	display:inline-block;
	font-size:24px;
	text-align:center;
}
.team-container .team-member figure,
.team-container .team-member figcaption,
.team-container .team-member img,
.team-container .team-member .social-buttons{

	-webkit-transition:all .25s;
	   -moz-transition:all .25s;
	    -ms-transition:all .25s;
	     -o-transition:all .25s;
	        transition:all .25s;
}
.team-container .team-member:hover figure{
	background-color:#3498db;
	color:#fff;
}
.team-container .team-member:hover img{
	opacity: 0.85;
}
.team-container .team-member:hover figcaption{
	background-color: rgba(0,0,0,0.9);
}

.team-container .team-member:hover .social-buttons{
	top:90px;
}
