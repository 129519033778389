/* 5 - About Us Section
---------------------------------------------------------------------- */
.partner-container .col-sm-4{
	padding:60px 15px 0 15px;
}
.partner-container .partner-member{
	max-width:245px;
	margin:auto;
	position:relative;
	overflow:hidden;
	margin-top: 15px;
	margin-bottom: 15px;
}


.partner-container figcaption{
	text-align:center;
	text-transform:uppercase;
	width:100%;
	height:110px;
	border:1px solid #fff;
	border-top-color:transparent;
	margin:0;
}
.partner-container figcaption h4{
	font-size:22px;
	font-weight:700;
	padding-top:20px;
}
.partner-container .member-name {
	/*font-size: 1.5em;*/
	font-weight: 700;
	margin: 0;
	padding: 26px 10px 5px 10px;
}
.partner-container figcaption p{
	font-size: 18px;
}
.partner-container .social-buttons{
	display:inline-block;
	margin:auto;
	text-align:center;
	width:100%;
}
.partner-member {
	border: 1px;
	border-color: rgba(0,0,0,0.8);
	border-style: solid;
}

.partner-member .social-btn-container,
.partner-member .social-btn-container .social-btn-box{
	-webkit-transition:all .25s;
	   -moz-transition:all .25s;
	    -ms-transition:all .25s;
	     -o-transition:all .25s;
	        transition:all .25s;
}

.partner-member .social-btn-container {
	position: absolute;
	top: 0px;
	margin: 0 auto;
	left: 0;
}
.partner-member:hover .social-btn-container{
	background: rgba(0,0,0,.5);
}
.partner-member .social-btn-container .partner-socail-btn{
	overflow: hidden;
	position: relative;
	width:245px;
	height: 230px;
}
.partner-member .social-btn-container .social-btn-box{
	position: absolute;

}
.partner-member .social-btn-container .facebook-btn-container{
	bottom: 220px;
	right: 230px;
}
.partner-member .social-btn-container .twitter-btn-container{
	bottom: 220px;
	left: 230px;
}
.partner-member .social-btn-container .linkedin-btn-container{
	top: 220px;
	right: 230px;
}
.partner-member .social-btn-container .github-btn-container{
	top: 220px;
	left: 230px;
}

.partner-member:hover .social-btn-container .facebook-btn-container{
	bottom: 115px;
	right: 122px;
}
.partner-member:hover .social-btn-container .twitter-btn-container{
	bottom: 115px;
	left: 122px;
}
.partner-member:hover .social-btn-container .linkedin-btn-container{
	top: 115px;
	right: 122px;
}
.partner-member:hover .social-btn-container .github-btn-container{
	top: 115px;
	left: 122px;
}
.partner-container .social-buttons a{
	color:#fff;
	width:24px;
	height:24px;
	display:inline-block;
	font-size:24px;
	text-align:center;
}
.partner-container .partner-member figure,
.partner-container .partner-member figcaption,
.partner-container .partner-member img,
.partner-container .partner-member .social-buttons{

	-webkit-transition:all .25s;
	   -moz-transition:all .25s;
	    -ms-transition:all .25s;
	     -o-transition:all .25s;
	        transition:all .25s;
}
.partner-container .partner-member:hover figure{
	background-color:rgba(0,0,0,0.8);
	color:#fff;
}
.partner-container .partner-member:hover figcaption{
	background-color: rgba(0,0,0,0.8);
	border-color:rgba(0,0,0,0.8);
	color:#fff;
}

.partner-container .partner-member:hover .social-buttons{
	top:90px;
}
