.App {
  text-align: center;
  color: black;
  overflow-x: hidden;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

#page-top {
  text-align: left;
}

.title-page {
  text-align: left;
}

/* parteners page */
.card {
  width: auto;
  border-radius: 0px;
}

/* home */
.grid-home figure{
  padding: 10px;
}
